<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar Evento</h1>

    <h3 class="form-division">Información general</h3>

    <b-tooltip
      label="Los eventos globales pueden ser vistos por todos los usuarios"
      multilined
      position="is-right"
      class="special-margin"
    >
      <b-checkbox true-value="GL" false-value="PE" v-model="newEvent.TIPO"
        >Evento global</b-checkbox
      >
    </b-tooltip>

    <b-field
      label="Nombre (Requerido)"
      expanded
      label-position="inside"
      :type="{ 'is-danger': error.NOMBRE }"
      :message="{ 'El nombre del evento no es valido': error.NOMBRE }"
      class="special-margin"
    >
      <b-input
        placeholder="Ejemplo: Descuento de Mayo"
        expanded
        icon="file-outline"
        v-model="newEvent.NOMBRE"
        required
      ></b-input>
    </b-field>

    <b-field
      label="Descripción (Requerido)"
      expanded
      label-position="inside"
      class="special-margin"
    >
      <b-input
        placeholder="Ejemplo: Productos o herramientas para un electricista"
        expanded
        v-model="newEvent.DESCRIPCION"
        type="textarea"
        required
      ></b-input>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': error.FECHA_INICIO }"
        :message="{
          'La fecha de inicio del evento no es valida': error.FECHA_INICIO,
        }"
      >
        <template slot="label">
          Inicio (Requerido)
          <b-tooltip multilined label="Feha y hora de inicio del evento">
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-datetimepicker
          :datetime-formatter="
            (date) => moment(date).format('DD/MM/YYYY HH:mm')
          "
          :datepicker="datepicker"
          v-model="newEvent.FECHA_INICIO"
          placeholder="Ejemplo 20/10/2018"
          trap-focus
          icon="calendar-today"
          horizontal-time-picker
          class="required-input"
          required
        >
        </b-datetimepicker>
      </b-field>
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': error.FECHA_FIN }"
        :message="{
          'La fecha de fin del evento no es valida': error.FECHA_FIN,
        }"
      >
        <template slot="label">
          Fin (Requerido)
          <b-tooltip multilined label="Feha y hora a la que termina el evento">
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-datetimepicker
          :datetime-formatter="
            (date) => moment(date).format('DD/MM/YYYY HH:mm')
          "
          :datepicker="datepicker"
          v-model="newEvent.FECHA_FIN"
          placeholder="Ejemplo 20/10/2018"
          trap-focus
          icon="calendar-today"
          horizontal-time-picker
          class="required-input"
          required
        >
        </b-datetimepicker>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': error.TIEMPO_NOTIFICACION }"
        :message="{
          'La fecha de inicio del evento no es valida':
            error.TIEMPO_NOTIFICACION,
        }"
      >
        <template slot="label">
          Envio de notificación antes del evento (Requerido)
          <b-tooltip
            multilined
            label="Se envia un correo a la cuenta de correo del usuario que creo el evento"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-select
          placeholder="Ejemplo: No enviar notificación"
          expanded
          v-model="newEvent.TIEMPO_NOTIFICACION"
          icon="clock"
          class="required-input"
        >
          <option value="0">No enviar notificación</option>
          <option value="5">5 minutos antes</option>
          <option value="10">10 minutos antes</option>
          <option value="15">15 minutos antes</option>
          <option value="30">30 minutos antes</option>
          <option value="60">1 hora antes</option>
          <option value="120">2 hora antes</option>
          <option value="1440">Al iniciar mismo día</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': error.TIEMPO_NOTIFICACION_SEG }"
        :message="{
          'El tiempo seleccionado no es válido': error.TIEMPO_NOTIFICACION_SEG,
        }"
      >
        <template slot="label">
          Envio de segunda notificación antes del evento (Requerido)
          <b-tooltip
            multilined
            label="Se envia un correo a la cuenta de correo del usuario que creo el evento y a los correos ingresados en otros correos"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-select
          placeholder="Ejemplo: No enviar notificación"
          expanded
          v-model="newEvent.TIEMPO_NOTIFICACION_SEG"
          icon="clock"
          class="required-input"
        >
          <option value="0">No enviar notificación</option>
          <option value="5">5 minutos antes</option>
          <option value="10">10 minutos antes</option>
          <option value="15">15 minutos antes</option>
          <option value="30">30 minutos antes</option>
          <option value="60">1 hora antes</option>
          <option value="120">2 hora antes</option>
          <option value="1440">Al iniciar mismo día</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Compartir evento usuarios (Requerido)"
        expanded
        label-position="inside"
      >
        <template slot="label">
          Añadir usuarios a evento (Opcional)
          <b-tooltip
            multilined
            label="Estos usuarios tambien podrán ver y editar los eventos si tienen los permisos necesarios, también se envian recordatorios a esos usuarios"
          >
            <b-icon
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-taginput
          v-model="newEvent.USUARIOS"
          :data="users"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="email"
          icon="account"
          placeholder="Añadir usuarios"
        >
          <template #empty>No se encontraron usuarios</template>
        </b-taginput>
      </b-field>
    </b-field>

    <b-field
      label="Enviar recordatorios también a estos correos (Opcional)"
      expanded
      label-position="inside"
      class="special-margin"
    >
      <b-taginput
        v-model="newEvent.OTRO_DESTINOS"
        ellipsis
        icon="at"
        placeholder="Enviar recordatorios también a estos correos"
        :before-adding="(tagToAdd) => validateEmail(tagToAdd)"
      >
      </b-taginput>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="saveNewEvent()"
        >Crear evento</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
export default {
  name: "AddEventModal",
  components: {},
  data() {
    return {
      allUsers: this.users,
      moment: moment,
      datepicker: {
        "month-names": [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        "day-names": ["D", "L", "M", "M", "J", "V", "S"],
        "show-week-number": true,
        locale: "es-MX",
      },
      newEvent: {
        TIPO: "PE",
        NOMBRE: "",
        DESCRIPCION: "",
        FECHA_INICIO: new Date(this.dateInformation.date.toDateString()),
        FECHA_FIN: new Date(this.dateInformation.date.toDateString()),
        TIEMPO_NOTIFICACION: "0",
        TIEMPO_NOTIFICACION_SEG: "0",
        USUARIOS: [],
        OTRO_DESTINOS: [],
      },
      error: {
        NOMBRE: false,
        FECHA_INICIO: false,
        FECHA_FIN: false,
        TIEMPO_NOTIFICACION: false,
        TIEMPO_NOTIFICACION_SEG: false,
      },
    };
  },
  props: ["dateInformation"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     * @desc special validation function to validate date fields
     * @return {boolean} return true or false depending if the form is valid or not
     */
    validate() {
      this.error.NOMBRE = !this.newEvent.NOMBRE;
      this.error.FECHA_INICIO = moment(this.newEvent.FECHA_INICIO).isAfter(
        this.newEvent.FECHA_FIN
      );
      this.error.FECHA_FIN = moment(this.newEvent.FECHA_FIN).isBefore(
        this.newEvent.FECHA_INICIO
      );
      return (
        !this.error.NOMBRE && !this.error.FECHA_INICIO && !this.error.FECHA_FIN
      );
    },
    saveNewEvent() {
      if (this.validate()) {
        this.$store.dispatch("ADDEVENT", this.newEvent).then((response) => {
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el nuevo evento",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar el evento en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el evento en la base de datos, revise los campos obligatorios",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  computed: {
    users() {
      return this.$store.getters.USERS.map((singleUser) => {
        const userWithProps = { ...singleUser };
        userWithProps.permissionsName = userWithProps.permissions.name;
        return userWithProps;
      }).filter((singleUser) => {
        return singleUser.email !== this.userInformation.email;
      });
    },
    userInformation() {
      return this.$store.getters.USER;
    },
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  watch: {
    users() {
      this.allUsers = this.users;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
