<template>
  <div class="container calendar-main-container">
    <Navbar :showSearch="false"></Navbar>
    <div class="absolut-width calendar-second-section-container">
      <div class="upper-global-title">
        <span class=""> Calendario </span>
      </div>
      <div class="upper-global-hint">
        <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
        <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
      </div>
      <div class="control"></div>
      <FullCalendar
        :options="calendarOptions"
        ref="fullCalendar"
        v-if="renderComponent"
      >
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Navbar from "../../Global/components/Navbar";
import AddEventModal from "../components/AddEventModal";
import ShowEventModal from "../components/ShowEventModal";
import moment from "moment";
// @ is an alias to /src
export default {
  name: "Calendar",
  components: {
    Navbar,
    FullCalendar,
  },
  data() {
    return {
      moment: moment,
      renderComponent: true,
      buttonTitle: "Cambiar a calendario por semana",
      calendarOptions: {
        dateClick: this.handleDateClick,
        eventClick: this.openShowEvent,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        locale: "es",
        editable: true,
        droppable: true,
        displayEventTime: true,
        displayEventEnd: true,
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          meridiem: false,
        },
        eventDrop: (event) => {
          if (this.checkCanEditEvent(event.event.id)) {
            let newEditedEvent;
            let finalEvents = this.events.map((singleEvent) => {
              const eventWithProps = { ...singleEvent };
              if (eventWithProps.id === event.event.id) {
                eventWithProps.FECHA_INICIO = new Date(event.event.startStr);
                eventWithProps.FECHA_FIN = new Date(event.event.endStr);
                newEditedEvent = eventWithProps;
              }
              return eventWithProps;
            });
            this.$store
              .dispatch("EDITEVENT", newEditedEvent)
              .then((response) => {
                if (response === "Success") {
                  this.reloadInformation();
                  this.events = finalEvents;
                  this.$buefy.dialog.confirm({
                    title: "Éxito",
                    message: "Se editó exitosamente el nuevo evento",
                    confirmText: "Entendido",
                    type: "is-success",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                } else {
                  this.$buefy.dialog.confirm({
                    title: "Error",
                    message:
                      "Hubo un error al editar el evento en la base de datos",
                    confirmText: "Entendido",
                    type: "is-danger",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                }
              });
          }
        },
        events: this.events,
        headerToolbar: {
          center: "dayGridMonth,dayGridWeek,dayGridDay",
        }, // buttons for switching between views
        buttonText: {
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día",
          list: "Lista",
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "calendar");
    this.calendarOptions = { ...this.calendarOptions, events: this.events };
    moment.locale("es");
  },
  async created() {
    let allPromises = [];
    if (this.$store.getters.EVENTS.length === 0) {
      allPromises.push(this.$store.dispatch("GETEVENTS"));
    }
    await Promise.all(allPromises);
  },
  methods: {
    async reloadInformation() {
      // Reload events
      let allPromises = [];
      allPromises.push(this.$store.dispatch("GETEVENTS"));
      await Promise.all(allPromises);
    },
    handleDateClick(e) {
      this.openAddEvent(e);
    },
    openAddEvent(dateInformation) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddEventModal,
        props: {
          dateInformation: dateInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openShowEvent(e) {
      for (let event of this.events) {
        if (event._id === e.event.id) {
          this.reloadInformation();
          this.$buefy.modal.open({
            canCancel: ["x"],
            parent: this,
            component: ShowEventModal,
            props: {
              eventInformation: event,
            },
            hasModalCard: false,
            customClass: "primary-modal-class",
            trapFocus: true,
            fullScreen: false,
            destroyOnHide: true,
          });
        }
      }
    },
    checkCanEditEvent(eventId) {
      for (const singleEvent of this.events) {
        if (singleEvent.id === eventId) {
          if (singleEvent.USUARIO_CREADOR === this.userInformation.email) {
            return true;
          }
          return false;
        }
      }
      return false;
    },
  },
  watch: {
    events() {
      this.calendarOptions = { ...this.calendarOptions, events: this.events };
    },
  },
  computed: {
    events() {
      return this.$store.getters.EVENTS.map((singleEvent) => {
        const eventWithProps = { ...singleEvent };
        eventWithProps.id = eventWithProps._id;
        eventWithProps.title = eventWithProps.NOMBRE;
        eventWithProps.allDay = false;
        eventWithProps.start = new Date(eventWithProps.FECHA_INICIO);
        eventWithProps.end = new Date(eventWithProps.FECHA_FIN);
        eventWithProps.startStr = moment(eventWithProps.FECHA_INICIO).format(
          "DD-MM-YYYY HH:mm"
        );
        eventWithProps.endStr = moment(eventWithProps.FECHA_FIN).format(
          "DD-MM-YYYY HH:mm"
        );
        eventWithProps.editable = true;
        eventWithProps.display = "block";
        eventWithProps.USUARIO_CREADOR;
        return eventWithProps;
      });
    },
    hint() {
      return this.$store.getters.HINT;
    },
    userInformation() {
      return this.$store.getters.USER;
    },
  },
};
</script>

<style scoped>
@import "../style/Calendar.css";
@import "../../Global/style/Global.css";
</style>
